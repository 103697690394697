.App {
  text-align: center;
}

.App-logo {
  /*animation: App-logo-spin infinite 20s linear;*/
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Ayo-link{
  color: white !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.md-grid-basic [class*="mbsc-col"] {
  /*padding-top: .5em;
  padding-bottom: .5em;*/
  /*background: #cfe0f3;*/
  background-clip: padding-box;
  border: 1px solid transparent;
  color: #454b50;
}

.md-grid-basic .mbsc-row {
  margin: 1em 0;
  /*margin-top: 1em;
  margin-bottom: 1em;*/
}

.md-grid-basic {
  /*margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;*/
  background: white !important;
  /*border-radius: 25px;*/
}

.ayo-grid{
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
}

.ayo-sticky{
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 100;
}

.ayo-logo{
  display: block;
  width: 70%;
  /*margin: 0 auto 0;*/
}

.ayo-blackboard{
  width: 100%;
}

.ayo-no-padding{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/*.ayo-blackboard2{
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(./analysis-blackboard-board-bubble-355952.jpg);
}

.ayo-africa{
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(./brown-map-on-map-2660262.jpg);
  background-position: 50% 50%;
}

.ayo-people{
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(./two-men-and-woman-standing-and-looking-at-smartphone-2361513.jpg);
}*/

.ayo-chalkboard{
  
  background-size: cover;
  background-image: url(./ux/close-up-of-wooden-plank-326311.jpg);
  /*background-image: url(./ux/abstract-antique-backdrop-background-164005.jpg);*/
  padding: 40px;

}

.ayo-text-align{
  text-align: center;
  font-size: 24px;
}

.ayo-text-1{
  color: white !important;
}

.ayo-text-mail a{
  color: white !important;
}

.ayo-menu{
  padding-top: 10px;
  padding-bottom: 10px;
}

.ayo-help-block{
  background-color: dimgray;
  padding-top: 30px;
  padding-bottom: 30px;
}

.ayo-subscribe-form{
  background-color: black;
  padding-top: 30px;
  padding-bottom: 30px;
  /*border-radius: 15px;*/
}

.ayo-blog-form{
  background-color: dimgray;
  padding-top: 30px;
  padding-bottom: 30px;
}

.ayo-subscribe{
  display: block !important;
  margin: 0 auto !important;
}

/* Logo on mobile */

@media (max-width: 500px) {
  .ayo-logo{
    display: block;
    width: 90%;
    /*margin: 0 auto 0;*/
  }
}

@media (min-width: 900px) {
  .large-menu a {
    color: DarkOrange;
    font-size: 30px;
  }
}

.small-menu a {
  color: Skyblue;
  text-decoration: line-through;
}
